import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/marekbaranowski/Desktop/dev/rxp-all/reactivepad/packages/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from "docz";
import DraftJS from "./plugins/DraftJS";
import { Editor } from "./plugins/loader";
import CKEditor from "./plugins/CKEditor";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "plugins-for-rich-text-editors"
    }}>{`Plugins for rich text editors`}</h1>
    <h2 {...{
      "id": "draftjs"
    }}><strong parentName="h2">{`DraftJS`}</strong></h2>
    <p><a parentName="p" {...{
        "href": "https://draftjs.org"
      }}>{`https://draftjs.org`}</a></p>
    <h3 {...{
      "id": "demo"
    }}>{`Demo`}</h3>
    <Playground __position={0} __code={'<DraftJS />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DraftJS,
      Editor,
      CKEditor
    }} mdxType="Playground">
  <DraftJS mdxType="DraftJS" />
    </Playground>
    <h3 {...{
      "id": "installation"
    }}>{`Installation`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm i @reactivepad/draftjs
# or
yarn add @reactivepad/draftjs
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React, { Component, Fragment } from "react";
import {
  convertFromRaw,
  CompositeDecorator,
  Editor,
  EditorState,
  Modifier,
  AtomicBlockUtils
} from "draft-js";
import { sample } from "./samples";
import {
  buildPlugin,
  ENTITY_TYPES,
  syncStoreWithDoc,
  formulaDecoratorStrategy
} from "@reactivepad/draftjs";

class DraftEditor extends Component {
  constructor(props) {
    super(props);

    this.reactivepadPlugin = buildPlugin({
      toggleReadOnly: this.toggleReadOnly
    });

    const {
      components: { Formula }
    } = this.reactivepadPlugin;

    const decorator = new CompositeDecorator([
      {
        strategy: formulaDecoratorStrategy,
        component: props => (
          <Formula {...props} updateContentState={this.updateContentState} />
        )
      }
    ]);

    const blocks = convertFromRaw(sample1);

    this.state = {
      editorState: EditorState.createWithContent(blocks, decorator),
      readOnly: false
    };
  }

  componentWillUnmount() {
    clearTimeout(this.formulaTimeoutId);
    this.reactivepadPlugin.destroy();
  }

  toggleReadOnly = (readOnly = !this.state.readOnly) => {
    this.setState({ readOnly });
  };

  onChange = (editorState, skipSyncWithStore) => {
    const prevContentState = this.state.editorState.getCurrentContent();
    this.setState({ editorState }, () => {
      if (
        !skipSyncWithStore &&
        prevContentState !== this.state.editorState.getCurrentContent()
        // don't sync when only selection has changed
      ) {
        syncStoreWithDoc(this.reactivepadPlugin.store, this.state.editorState);
      }
    });
  };

  updateContentState = (
    contentState,
    skipSyncWithStore, // sometimes sync is unnecessary
    editorState = this.state.editorState
  ) => {
    const newEditorState = EditorState.push(
      editorState,
      contentState,
      "insert-characters"
    );

    this.onChange(newEditorState, skipSyncWithStore);
  };

  insertFormula = e => {
    e.preventDefault();
    const { editorState } = this.state;

    const formula = this.reactivepadPlugin.store.createAndAddFormula();
    const whitespace = " ";
    let contentState = editorState.getCurrentContent();
    contentState = Modifier.replaceText(
      contentState,
      editorState.getSelection(),
      whitespace
    );

    const contentStateWithEntity = contentState.createEntity(
      ENTITY_TYPES.FORMULA,
      "IMMUTABLE",
      formula.serialize()
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const selectionState = editorState.getSelection();
    const contentStateWithFormula = Modifier.applyEntity(
      contentStateWithEntity,
      selectionState.merge({
        focusOffset: selectionState.getAnchorOffset() + whitespace.length
      }),
      entityKey
    );

    this.updateContentState(contentStateWithFormula, true);

    this.formulaTimeoutId = setTimeout(() => {
      formula.toggleEditing(true);
    }, 5);
  };

  insertTable = e => {
    e.preventDefault();
    const { editorState } = this.state;

    const table = this.reactivepadPlugin.store.createAndAddTable();
    const whitespace = " ";
    const contentStateWithEntity = editorState
      .getCurrentContent()
      .createEntity(ENTITY_TYPES.TABLE, "IMMUTABLE", table.serialize());
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    let newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });
    newEditorState = AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      whitespace
    );

    this.onChange(newEditorState);
  };

  blockRenderer = block => {
    const entityKey = block.getEntityAt(0);

    if (!entityKey) {
      return null;
    }

    const entity = this.state.editorState
      .getCurrentContent()
      .getEntity(entityKey);
    const type = entity.getType();

    if (type !== ENTITY_TYPES.TABLE) {
      return null;
    }

    const { Table } = this.reactivepadPlugin.components;

    return {
      component: props => (
        <Table {...props} updateContentState={this.updateContentState} />
      ),
      editable: false
    };
  };

  render() {
    const { editorState, readOnly } = this.state;

    return (
      <Fragment>
        <button onClick={this.insertFormula}>Formula</button>
        <button onClick={this.insertTable}>Table</button>
        <div style={{ minHeight: "6rem" }}>
          <Editor
            editorState={editorState}
            onChange={this.onChange}
            blockRendererFn={this.blockRenderer}
            readOnly={readOnly}
          />
        </div>
      </Fragment>
    );
  }
}
`}</code></pre>
    <hr></hr>
    <h2 {...{
      "id": "prosemirror"
    }}><strong parentName="h2">{`ProseMirror`}</strong></h2>
    <p><a parentName="p" {...{
        "href": "https://prosemirror.net"
      }}>{`https://prosemirror.net`}</a></p>
    <h3 {...{
      "id": "demo-1"
    }}>{`Demo`}</h3>
    <Playground __position={1} __code={'<Editor />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DraftJS,
      Editor,
      CKEditor
    }} mdxType="Playground">
  <Editor mdxType="Editor" />
    </Playground>
    <h3 {...{
      "id": "installation-1"
    }}>{`Installation`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm i @reactivepad/prosemirror
# or
yarn add @reactivepad/prosemirror
`}</code></pre>
    <p>{`Below is slightly a modified version of a basic ProseMirror sample setup from official documentation `}<a parentName="p" {...{
        "href": "https://prosemirror.net/examples/basic"
      }}>{`https://prosemirror.net/examples/basic`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const {
  buildPlugin,
  nodes,
  menuItems,
  buildMenuDropdown
} = "@reactivepad/prosemirror";

// create a plugin
const reactivepadPlugin = buildPlugin();

// extend default editor schema with nodes from Reactivepad
const editorSchema = new Schema({
  nodes: addListNodes(schema.spec.nodes, "paragraph block*", "block").append(
    nodes
  ),
  marks: schema.spec.marks
});

// add Reactivepad's dropdown to menubar
const menu = buildMenuItems(editorSchema);
menu.fullMenu.unshift([buildMenuDropdown(menuItems)]);

// create ProseMirror editor instance with Reactivepad plugin
new EditorView(this.editorRef, {
    state: EditorState.create({
      schema: editorSchema,
      plugins: [
        ...exampleSetup({
              schema: editorSchema,
              menuContent: menu.fullMenu
          }),
       reactivepadPlugin.plugin
      ]
    })
  });
}
`}</code></pre>
    <hr></hr>
    <h2 {...{
      "id": "ckeditor4"
    }}><strong parentName="h2">{`CKEditor4`}</strong></h2>
    <p><a parentName="p" {...{
        "href": "https://ckeditor.com/ckeditor-4"
      }}>{`https://ckeditor.com/ckeditor-4`}</a></p>
    <h3 {...{
      "id": "demo-2"
    }}>{`Demo`}</h3>
    <Playground __position={2} __code={'<CKEditor />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DraftJS,
      Editor,
      CKEditor
    }} mdxType="Playground">
  <CKEditor mdxType="CKEditor" />
    </Playground>
    <h3 {...{
      "id": "installation-2"
    }}>{`Installation`}</h3>
    <ul>
      <li parentName="ul">{`Extract the contents of this `}<inlineCode parentName="li">{`.zip`}</inlineCode>{` file to `}<inlineCode parentName="li">{`<CKEditor folder>/plugins/reactivepad`}</inlineCode>{`.
Download link: `}<a parentName="li" {...{
          "href": "https://github.com/reactivepad/reactivepad-ckeditor4/archive/master.zip"
        }}>{`https://github.com/reactivepad/reactivepad-ckeditor4/archive/master.zip`}</a></li>
      <li parentName="ul">{`Add `}<inlineCode parentName="li">{`reactivepad`}</inlineCode>{` to the list of extra plugins:`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`CKEDITOR.replace("editorId", {
    extraPlugins: "reactivepad"
});
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`This plugin requires "widget" and "dialog" plugins, please make sure to have them installed.`}</strong></p>
    </blockquote>
    <p>{`For more information on CKEditor plugins installation, please refer to the official manual:
`}<a parentName="p" {...{
        "href": "https://ckeditor.com/docs/ckeditor4/latest/guide/dev_plugins.html#manual-installation"
      }}>{`https://ckeditor.com/docs/ckeditor4/latest/guide/dev_plugins.html#manual-installation`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      